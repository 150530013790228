import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import SizeRecommendationsPage from './pages/SizeRecommendationsPage';
import ResultsPage from './pages/ResultsPage';
import AdminPage from './pages/AdminPage';
import NotFoundPage from './pages/NotFoundPage'
import LandingPage from './pages/LandingPage'


function App() {
  return (
    <Router>
      <Routes>
        {/* Existing Routes */}
        <Route index element={<LandingPage />} />
        <Route path="/brand/:brand/category/:category/subcategory/:subcategory" element={<SizeRecommendationsPage />} />
        <Route path="/brand/:brand/category/:category" element={<SizeRecommendationsPage />} />
        <Route path="/brand/:brand/subcategory/:subcategory" element={<SizeRecommendationsPage />} />
        <Route path="/brand/:brand" element={<SizeRecommendationsPage />} />
        <Route path="/retailer/:retailer" element={<SizeRecommendationsPage />} />
        <Route path="/category/:category/subcategory/:subcategory" element={<SizeRecommendationsPage />} />
        <Route path="/category/:category" element={<SizeRecommendationsPage />} />
        <Route path="/results/:userId" element={<ResultsPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
