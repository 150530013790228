import React from 'react';
import { Link } from 'react-router-dom';

const DealCard = ({ deal }) => {
  // Construct the URL for the retailer's page
  const retailerUrl = deal.name 
  ? `/retailer/${deal.name.replace(/\s+/g, '-').toLowerCase()}`
  : '/retailer/default'

  return (
    <div className="  rounded-2xl overflow-hidden shadow shadow-xl  relative">
      <Link to={retailerUrl}>
        <div className="relative">
          <img src={deal.logo} alt="Logo" className="absolute rounded-full border-2 top-4 left-0 m-4 w-12 h-12" />
          <img className="w-full h-96" src={deal.image} alt={`${deal.name} offer`} />

          <div className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4">
            <span className="text-primary text-white text-base  md:text-2xl md:font-bold">
              {deal.discount}
            </span>

            <div className="mt-2">
              <h3 className="text-white text-sm md:text-2xl md:font-bold">{deal.name}</h3>
            </div>
          </div>
        </div>
      </Link>

      <div className="mt-4">
      <Link to={retailerUrl}>
        <button className="w-full bg-secondary hover:bg-primary hover:text-base-100 text-white font-bold py-2 px-4 rounded-xl">
          Find Your Size
        </button>
        </Link>
      </div>
    </div>
  );
};

export default DealCard;
