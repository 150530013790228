import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import SizeChart from '../components/admin/sizeChart';
import {
  StoresCreateForm 
 } from '../ui-components';
 


const AdminPage = () => {
  // Sample individual data (This will be fetched from your database)
  const individualData = [
    { id: 1, purchased: true, category: 'Clothing', country: 'USA', height: '5ft 9in' },
    // Add more sample data...
  ];

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#EAEBF2] via-[#EAEFF6] to-[#E2E7F4] flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-screen-lg">
        <h1 className="text-2xl font-bold mb-4">Retailer Dashboard</h1>
        <StoresCreateForm />
        <SizeChart />
        
        {/* Individual Data Section */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Individual Data</h2>
          <table className="min-w-full border">
            <thead>
              <tr>
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">Purchased</th>
                <th className="border px-4 py-2">Category</th>
                <th className="border px-4 py-2">Country</th>
                <th className="border px-4 py-2">Height</th>
              </tr>
            </thead>
            <tbody>
              {individualData.map((data) => (
                <tr key={data.id}>
                  <td className="border px-4 py-2">{data.id}</td>
                  <td className="border px-4 py-2">{data.purchased ? 'Yes' : 'No'}</td>
                  <td className="border px-4 py-2">{data.category}</td>
                  <td className="border px-4 py-2">{data.country}</td>
                  <td className="border px-4 py-2">{data.height}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Aggregated Data Section */}
        <div>
          <h2 className="text-xl font-semibold mb-2">Aggregated Data</h2>
          <p className="text-gray-700">Charts and graphs will go here to display average body shape, insights, etc.</p>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticator(AdminPage, { hideSignUp: true });
