import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const FitPreference = () => {
  // State to track selected size chart: 'women', 'men', or 'unisex'
  const [sizeChart, setSizeChart] = useState(null);
  // State to track fit preference: 'Fitted', 'Average', 'Loose'
  const [fitPreference, setFitPreference] = useState(null);

  const { control, setValue } = useFormContext(); // Using useFormContext to get methods

  const handleSizeChart = (value) => {
    setSizeChart(value);
    setValue('sizeChart', value);
  };

  const handleFitPreference = (value) => {
    setFitPreference(value);
    setValue('fitPreference', value);
  };

  return (
    <>
      <h1 className="text-2xl font-bold mb-4 text-black">Fit Preference</h1>
      <div className="mb-4">
        <p className="mb-2 text-black">What size chart would you like to use?</p>
        {['Women\'s', 'Men\'s', 'Unisex'].map((label, index) => (
          <button 
            key={index} 
            className={`bg-secondary text-white py-2 px-4 rounded mr-2 ${sizeChart === label ? 'border-4 border-black  ' : ''}`} 
            onClick={() => handleSizeChart(label)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="mb-4">
        <p className="mb-2 text-black">What is your fit preference?</p>
        {['Fitted', 'Average', 'Loose'].map((label, index) => (
          <button 
            key={index} 
            className={`bg-secondary text-white py-2 px-4 rounded mr-2 ${fitPreference === label ? 'border-4 border-black  ' : ''}`} 
            onClick={() => handleFitPreference(label)}
          >
            {label}
          </button>
        ))}
      </div>
    </>
  );
};

export default FitPreference;
