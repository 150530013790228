import React from 'react';
import "./Header.css"

const productHuntBadgeHTML = {
  __html: '<a href="https://www.producthunt.com/posts/find-my-size?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-find&#0045;my&#0045;size" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=426144&theme=light" alt="Find My Size - Get size recommendations for online stores w AI camera scan | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>'
};


const Hero = () => {
  return (
    <div className="bg-base-100 ">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 items-center">
        {/* Left Column */}
        <div className="text-left text-white">
          <h1 className="text-9xl font-bold px-8 pt-8 ">Find Your <br/><span className="text-primary font-londrina-outline">SIZE </span></h1>
    
          <p className="text-2xl px-8 max-w-3xl">Personalized Size Recommendations for Exclusive Deals at Hundreds of Top Retailers</p>
          <div className='p-8' dangerouslySetInnerHTML={productHuntBadgeHTML} />
        </div>
        {/* Right Column */}
        <div className="custom-shape h-96">
      
        </div>
      </div>
      {/* Infinite Scrolling Banner */}
      <div className="border-y-8 border-primary">
      <div class="relative flex overflow-x-hidden">
  <div class="py-12 animate-marquee whitespace-nowrap ">
    <span class="text-4xl font-bold text-white  mx-4">✨  The Perfect Fit </span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">🔥  The Best Deals</span>
    <span class="text-4xl font-bold text-white mx-4">✨  The Perfect Fit</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4"> 🔥  The Best Deals</span>
    <span class="text-4xl font-bold text-white   mx-4 pr-6">✨The Perfect Fit  </span>
  </div>

  <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap ">
    <span class="text-4xl font-bold text-wg font-londrina-outline  mx-4">🔥 The Best Deals</span>
    <span class="text-4xl font-bold text-white   mx-4">✨ The Perfect Fit</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">🔥 The Best Deals</span>
    <span class="text-4xl font-bold text-white  mx-4">✨ The Perfect Fit</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">   🔥 The Best Deals</span>
  </div>
</div>
      </div>
      
    </div>
  );
};

export default Hero;

