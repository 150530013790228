
import { API } from "aws-amplify";
import { listSizeCharts, getSizeChart } from "../graphql/queries";

// Function to get the right size charts using AWS Amplify GraphQL API
export const getSizeChartsFromAPI = async (brand, category, subCategory, sizeChartType, retailer) => {
  console.log("Get Size Charts C")
  try {
    // Replace hyphens with spaces and convert to lowercase
    brand = brand ? brand.replace(/-/g, ' ').toLowerCase() : brand;
    category = category ? category.replace(/-/g, ' ').toLowerCase() : category;
    subCategory = subCategory ? subCategory.replace(/-/g, ' ').toLowerCase() : subCategory;
   retailer = retailer? retailer.replace(/-/g, ' ').toLowerCase() : retailer;


    // Dynamically build the filter object based on available fields
    const filter = {};
    if (retailer) filter.retailer = { eq: retailer};
    if (brand) filter.brand = { eq: brand };
    if (category) filter.category = { eq: category };
    if (subCategory) filter.sub_category = { eq: subCategory };
    if (sizeChartType && sizeChartType !== 'Unisex') filter.type = { eq: sizeChartType };
    console.log("Got filter D")

    // Prepare the variables for the GraphQL query
    const variables = { filter: Object.keys(filter).length ? filter : undefined };

    // Query size charts from the database with filtering
    let result = await API.graphql({ 
      query: listSizeCharts, 
      variables: variables 
    });
    ;
  
    // Special case for 'Unisex': if no size charts are found, fetch all size charts
    if (sizeChartType === 'Unisex' && result.data.listSizeCharts.items.length === 0) {
      result = await API.graphql({ query: listSizeCharts });
    }
   
    // Transform GraphQL response into the desired format
    const transformedSizeCharts = result.data.listSizeCharts.items.map(item => ({
      brand: item.brand,
      chart: item.SizeEntries.items.map(entry => ({
        size: entry.size,
        bust: parseInt(entry.bust, 10),
        waist: parseInt(entry.waist, 10),
        hips: parseInt(entry.hips, 10)
      })),
      category: item.category,
      subCategory: item.sub_category
    }));
    console.log("Transform Data F")
    return transformedSizeCharts;
  } catch (error) {
    console.error("Error fetching size charts:", error);
    return [];
  }
};