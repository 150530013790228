import Header from '../components/landing-page/Header'
import FeaturedDeals from '../components/landing-page/FeaturedDeals';
import StoreSection from '../components/landing-page/StoreSection';
import Footer from '../components/common/Footer';

const LandingPage = () => {
    return (
      <div>
        <Header />
          <FeaturedDeals />     
          <StoreSection />
          <Footer />
      </div>
    );
  };
  
  export default LandingPage;
  