import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const BodyMeasurement = () => {
  const [entryOption, setEntryOption] = useState(null);
  const { control, setValue } = useFormContext();
  const [apiResults, setApiResults] = useState(null);

  const handleAutomatic = () => {
    setEntryOption('automatic');
  };

  const fetchMeasurements = async (username) => {
    try {
      const response = await fetch('https://sleepy-retreat-02115.herokuapp.com/username', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tenant: 'findmysize',
          usernameToSearch: username,
        }),
      });
      const data = await response.json();
      const newData = Object.values(data.data)[0].data.data;
      setApiResults(newData);
      
      // Update form values
      setValue("Bust", newData.Bust);
      setValue("Waist", newData.Waist);
      setValue("Hips", newData.Hip);
  
    } catch (error) {
      console.error('Error fetching measurements:', error);
    }
  };
  

  useEffect(() => {
    if (entryOption === 'automatic') {
      const userId = document.cookie.replace(/(?:(?:^|.*;\s*)userId\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      const newSrc = `https://aimirr-staging.herokuapp.com/MeasurementApp/stagingMay21/dist/navigation.html?tenant=findmysize&username=${userId}`;
      document.getElementById('dataIframe').src = newSrc;

      const messageEventListener = (event) => {
        if (event.data === 'close-iframe') {
          document.getElementById('dataIframe').style.display = 'none';
          fetchMeasurements(userId);
        }
      };

      window.addEventListener('message', messageEventListener);

      return () => {
        window.removeEventListener('message', messageEventListener);
      };
    }
  }, [entryOption]);


  return (
    <>
      <h1 className="text-2xl font-bold mb-4 text-black">Choose Your Size Entry Option</h1>
      <div className="mb-4">
        <button className="bg-secondary text-white py-2 px-4 rounded mr-2" onClick={() => setEntryOption('manual')}>Manual</button>
        <button className="bg-secondary text-white py-2 px-4 rounded" onClick={handleAutomatic}>Automatic</button>
      </div>
      {entryOption === 'manual' && (
      <form>
      <Controller
        name="Bust"
        control={control}
        render={({ field }) => <input {...field} className="border p-2 mb-2 w-full" placeholder="Bust" />}
      />
      <Controller
        name="Waist"
        control={control}
        render={({ field }) => <input {...field} className="border p-2 mb-2 w-full" placeholder="Waist" />}
      />
      <Controller
        name="Hips"
        control={control}
        render={({ field }) => <input {...field} className="border p-2 mb-2 w-full" placeholder="Hips" />}
      />
    </form>
      )}
  {entryOption === 'automatic' && (
        <div className="border p-4">
          {apiResults ? (
            <div>
              <p>Bust: {apiResults.Bust}</p>
              <p>Waist: {apiResults.Waist}</p>
              <p>Hips: {apiResults.Hip}</p>
              <p>Shoulder: {apiResults.Shoulder}</p>
              <p>Left Thigh: {apiResults.LeftThigh}</p>
              <p>Right Thigh: {apiResults.RightThigh}</p>
            </div>
          ) : (
            <iframe id="dataIframe" width="100%" height="600" allow="camera"></iframe>
          )}
        </div>
      )}
    </>
  );
};

export default BodyMeasurement;
