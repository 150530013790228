import React, { useState, useEffect, useRef } from 'react';
import DealCard from './DealCard';
import { API, graphqlOperation } from 'aws-amplify';
import { listStores } from '../../graphql/queries';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const StoreSection = () => {
  const [stores, setStores] = useState([]);
  const [displayedStores, setDisplayedStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('View All');
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);

  const itemsPerPage = 8;

  const filters = [
    'View All', 'Active & Sportswear', 'Young Contemporary',
    'Business & Workwear', 'Lingerie & Sleepwear', 'Outerwear',
    'Maternity Wear', 'Plus Size Apparel', 'Swimwear',
    'Department Store', 'Marketplace'
  ];

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const storeData = await API.graphql(graphqlOperation(listStores));
        setStores(storeData.data.listStores.items);
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    const loadMoreStores = () => {
      if (loading || displayedStores.length >= stores.length) return;
      setLoading(true);
      setTimeout(() => {
        const newStores = stores.slice(0, displayedStores.length + itemsPerPage);
        setDisplayedStores(newStores);
        setLoading(false);
      }, 1000); // simulate network delay
    };

    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        loadMoreStores();
      }
    };

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [stores, displayedStores.length, loading]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredStores = displayedStores.filter(store => {
    return (selectedFilter === 'View All' || store.category === selectedFilter) &&
           (searchTerm ? store.name.toLowerCase().includes(searchTerm) : true);
  });

  return (
    <div className="p-8 bg-white">
      <div className="max-w-7xl mx-auto ">
        <h2 className='text-4xl font-bold text-black my-4 text-center'>💖🔥Perfect Fit, Perfect Price 🔥💖</h2>
        <div className="relative w-full mb-4">
          <input 
            type="text" 
            placeholder="Search for a store" 
            value={searchTerm} 
            onChange={handleSearchChange} 
            className="w-full pl-10 pr-4 py-2 border border-gray-500 rounded-full"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
          </span>
        </div>

        <div className="flex justify-center flex-wrap gap-2 mb-4">
          {filters.map((filter) => (
            <button
              key={filter}
              onClick={() => handleFilterClick(filter)}
              className={`px-4 py-2 border hover:bg-secondary hover:text-white rounded ${selectedFilter === filter ? 'bg-secondary text-white' : 'bg-white text-base-100'}`}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 md:px-24 pt-8">
        {filteredStores.map(store => (
          <DealCard key={store.id} deal={store} />
        ))}
        {displayedStores.length < stores.length && (
          <div ref={loader} className="col-span-full text-center">
            {loading && <span className="loading loading-dots loading-lg text-secondary"></span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreSection;