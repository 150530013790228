import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-6xl font-bold text-gray-900">404</div>
      <div className="text-xl font-medium text-gray-700">Page Not Found</div>
      <Link to="/">
        <button className="mt-4 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Go to Homepage
        </button>
      </Link>
    </div>
  );
};

export default NotFound;

