
import { API } from "aws-amplify";
import { createFinalFormData } from '../graphql/mutations';
import { getSizeChartsFromAPI } from "./GetSizeChartsFromAPI";


export const onSubmit = async (collectedData, getValues, navigate, location, brand, category, subcategory, retailer) => {
  console.log("On Submit Handler A")
  const finalData = { ...collectedData, ...getValues() };
  
  const userID = document.cookie
      .split('; ')
      .find(row => row.startsWith('userId='))
      ?.split('=')[1];
console.log("Got userID B")
  // Extract user measurements and other fields
  const { Bust, Waist, Hips, email, fitPreference, sizeChart } = finalData;
  const appropriateSizeCharts = await getSizeChartsFromAPI(brand, category, subcategory, sizeChart, retailer);
  console.log("Size Charts Fetched G", appropriateSizeCharts)

  const userMeasurements = {
    bust: parseInt(Bust, 10),
    waist: parseInt(Waist, 10),
    hips: parseInt(Hips, 10),
  };
console.log("User Measurements H")
  try {
    const newFinalFormData = await API.graphql({
      query: createFinalFormData,
      variables: {
        input: {
          bust: userMeasurements.bust,
          waist: userMeasurements.waist,
          hips: userMeasurements.hips,
          email: email,
          fitPreference: fitPreference,
          userID, // Use the dynamic userID
        },
      },
    });
    console.log("Final form data saved I:", newFinalFormData);
  } catch (error) {
    console.error("Failed to save final form data:", error);
  }







  // Prepare the payload
  const payload = {
    userMeasurements,
    fitPreference,
    sizeCharts: appropriateSizeCharts,
  };

  try {
    // Make the API call
    const apiName = "testexpress";
    const myInit = {
      headers: { "Content-Type": "application/json" },
      body: payload,
    };
    const response = await API.post(apiName, "/test", myInit);
    localStorage.setItem("sizeResults", JSON.stringify(response));

    // Log the response
    console.log("API Response:", response);

     // Navigate to results page
    navigate(`/results/${userID || 'someUserId'}`);
  } catch (error) {
    console.error("API call failed:", error);
  }
};