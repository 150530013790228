
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import WelcomeScreen from '../components/size-recommendations/steps/WelcomeScreen';
import BodyMeasurement from '../components/size-recommendations/steps/BodyMeasurements';
import FitPreference from '../components/size-recommendations/steps/FitPreference';
import EmailCapture from '../components/size-recommendations/steps/EmailCapture';
import { onSubmit } from './submitHandler'; 
import { useParams } from "react-router-dom"
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const SizeRecommendationsLayout = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [collectedData, setCollectedData] = useState({});
  const methods = useForm();
  const { handleSubmit, getValues } = methods;
  const navigate = useNavigate();
  const location = useLocation(); 

  let nextButtonText = 'Next';
  if (currentStep === 1) nextButtonText = "Let's Get Started";
  if (currentStep === 4) nextButtonText = 'Submit';

  const goToNextStep = () => {
    setCollectedData({ ...collectedData, ...getValues() });
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goToPrevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  const { brand, category, subcategory, retailer } = useParams();


  const handleOnSubmit = async () => {
    setIsLoading(true);
    await onSubmit(collectedData, getValues, navigate, location, brand, category, subcategory, retailer);
  };

  return (
    <FormProvider {...methods}>
    <div className="h-screen grid grid-rows-[auto,1fr,auto] bg-base-100 ">
      <Header />
      <div className="flex justify-center items-center">
       <div className="flex flex-col  bg-white p-8 rounded-lg shadow-lg w-full m-4 md:w-1/2 lg:w-1/3">
        <div>
            {isLoading ? (
            <div className='flex items-center justify-center '>
            <span className="w-24 loading loading-bars loading-lg  bg-gradient-to-r from-primary via-secondary to-accent"></span>
        </div>
            ) : (
              <>
                {currentStep === 1 && <WelcomeScreen />}
                {currentStep === 2 && <BodyMeasurement />}
                {currentStep === 3 && <FitPreference />}
                {currentStep === 4 && <EmailCapture />}
              </>
            )}
          </div>
          <div className="mt-4 flex justify-between">
            {currentStep > 1 && (
              <button className="bg-secondary text-white py-2 px-4 rounded" onClick={goToPrevStep}>
                Back
              </button>
            )}
            {currentStep < 4 ? (
              <button className="bg-secondary text-white py-2 px-4 rounded" onClick={goToNextStep}>
                {nextButtonText}
              </button>
            ) : (
              <button className="bg-secondary text-white py-2 px-4 rounded" onClick={handleSubmit(handleOnSubmit)}>
                {nextButtonText}
              </button>
            )}
          </div>
        </div>
       </div>
        <Footer/>
      </div>
  
    </FormProvider>
   
  );
};

export default SizeRecommendationsLayout;