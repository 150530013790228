import React, {useEffect } from 'react';
import Cookies from 'js-cookie';
import { API } from "aws-amplify";
import { createUser as createUserMutation } from '../../../graphql/mutations';
import { useParams } from "react-router-dom"
/**
 * WelcomeScreen Component
 * 
 * Description: This component represents the welcome screen step of the size recommendation form.
 *
 * @component
 */

const WelcomeScreen = () => {
  useEffect(() => {
    // Check for a cookie
    let userId = Cookies.get('userId');

    if (!userId) {
      // Create new user in database
      createNewUser().then(newUserId => {
        // Save the user ID as a cookie
        Cookies.set('userId', newUserId, { expires: 365 });  // Expires in 365 days
      }).catch(err => {
        // Handle error (e.g., failed to create user)
        console.error("Failed to create user:", err);
      });
    }
    // If a cookie already exists, we do nothing
  }, []);

  const createNewUser = async () => {
    try {
      const newUser = {
        email: "",  // Empty string as a placeholder
      };
  
      const result = await API.graphql({
        query: createUserMutation,
        variables: { input: newUser }
      });
  
      return result.data.createUser.id;  // Assumes that the 'id' is under 'data.createUser'
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  };
  const { brand, category, subcategory, retailer } = useParams();


  // AI Feature
  const aiFeature = "Our AI-powered body scanning technology ensures you get the most accurate measurements.";


  return (
    <>
      <div className="text-center p-8">
        <h1 className=" text-3xl md:text-4xl font-bold mb-4 text-black">Calculate Your Perfect Fit</h1>
     
        <p className="text-gray-700 mb-4">{aiFeature}</p>
      </div>
    </>
  );
};

export default WelcomeScreen;
