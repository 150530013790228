import React from 'react';
import SizeRecommendationsLayout from '../layouts/SizeRecommendationsLayout';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const SizeRecommendationsPage = () => {
  return (
    <div>
      <SizeRecommendationsLayout />
    </div>
  );
};

export default SizeRecommendationsPage;
