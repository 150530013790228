import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { listStores } from '../../graphql/queries';

const formatParam = (param) => {
  if (!param) return '';
  return param
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default function Header() {
  const { retailer } = useParams();
  const [storeData, setStoreData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const storeDataResult = await API.graphql(graphqlOperation(listStores));
        const formattedData = storeDataResult.data.listStores.items.reduce((acc, store) => {
          let dealText = store.promoCode 
                        ? `${store.discount} off with code ${store.promoCode}`
                        : `Enjoy ${store.discount}!`;
          acc[store.name] = {
            logo: store.logo,
            dealText: dealText,
          };
          return acc;
        }, {});
        setStoreData(formattedData);
  
        // Find the specific store's website and store it in local storage
        const specificStoreWebsite = storeDataResult.data.listStores.items.find(store => store.name === formatParam(retailer))?.website;
        if (specificStoreWebsite) {
          localStorage.setItem('specificStoreWebsite', specificStoreWebsite);
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
      setIsLoading(false);
    };
  
    fetchStores();
  }, [retailer]); // Add 'retailer' as a dependency
  
  


  const formattedRetailer = formatParam(retailer);
  const retailerData = storeData[formattedRetailer] || {
    logo: 'https://source.unsplash.com/random/500x500?logo', // default logo URL
    dealText: 'Special Offer!'
  };
  const header = `Find Your ${formattedRetailer || ''} Size`;

  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="w-screen">
    <div className="px-6 pt-16 sm:pt-24 lg:px-8  text-white">
      <div className="mx-auto max-w-7xl text-center">
        <img src={retailerData.logo} alt={`${formattedRetailer} Logo`} className="mx-auto h-24 w-24 rounded-full mb-2 border-2 border-primary" />
        <h2 className="text-2xl font-bold tracking-tight sm:text-6xl">This Black Friday</h2>
        <h2 className="text-8xl font-bold tracking tight text-primary">{header}</h2>
      </div>
    </div>
    <div className="border-y-8 border-primary">
      <div class="relative flex overflow-x-hidden">
  <div class="py-12 animate-marquee whitespace-nowrap ">
    <span class="text-4xl font-bold text-white  mx-4"> 🔥 {retailerData.dealText} </span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4"> 🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white   mx-4 pr-6">🔥 {retailerData.dealText} </span>
  </div>

  <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap ">
    <span class="text-4xl font-bold text-wg font-londrina-outline  mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white   mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white  mx-4">🔥 {retailerData.dealText}</span>
    <span class="text-4xl font-bold text-white font-londrina-outline  mx-4">🔥 {retailerData.dealText}</span>
  </div>
</div>
      </div>
    </div>
  )
}
