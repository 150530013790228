import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const EmailCapture = () => {
  const { control, formState: { errors } } = useFormContext(); // Using useFormContext to get methods

  return (
    <>
      <h1 className="text-2xl font-bold mb-4 text-black">Last Step</h1>
      <p className="text-black mb-4">Enter your email to receive your personalized size recommendations.</p>
      <Controller
        name="email"
        control={control}
        rules={{ required: "Email is required" }}
        render={({ field }) => (
          <input
            {...field}
            type="email"
            placeholder="Your Email"
            className={`border p-2 mb-2 w-full ${errors.email ? 'border-red-500' : ''}`}
          />
        )}
      />
      {errors.email && <span className="text-red-500">{errors.email.message}</span>}
    </>
  );
};

export default EmailCapture;
