import React, { useState } from 'react';
import { Storage, API } from 'aws-amplify';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import Papa from 'papaparse';
import { createSizeChart, createSizeEntry } from '../../graphql/mutations';
import { XCircleIcon } from '@heroicons/react/20/solid'


const SizeChart = () => {
  const [loading, setLoading] = useState(false);
  const [uploadedBrands, setUploadedBrands] = useState(null);
  const [error, setError] = useState(null);

  const resetState = () => {
    setError(null);
    setUploadedBrands(null);
  };

  const convertToDesiredJSON = (csvData) => {
    const nestedData = {};
    
    csvData.forEach((row, index) => {
      const { Retailer, Type, Category, SubCategory = 'N/A', Brand, Size, Bust, Waist, Hips } = row;
      
      let missingFields = [];
      if (Retailer == null) missingFields.push('Retailer');
      if (Type == null) missingFields.push('Type');
      if (Category == null) missingFields.push('Category');
      if (Brand == null) missingFields.push('Brand');
      if (Size == null) missingFields.push('Size');

      if (missingFields.length > 0) {
        const missingFieldsStr = missingFields.join(', ');
        throw new Error(`Row ${index + 1}: Missing required fields (${missingFieldsStr}). Row content: ${JSON.stringify(row)}`);
      }

      if (!["Women's", "Men's", "Unisex"].includes(Type)) {
        throw new Error(`Row ${index + 1}: Invalid Type entry. Must be "Women's", "Men's", or "Unisex". Row content: ${JSON.stringify(row)}`);
      }

      let notLowercasedFields = [];
      if (Retailer && Retailer !== Retailer.toLowerCase()) notLowercasedFields.push('Retailer');
      if (Category && Category !== Category.toLowerCase()) notLowercasedFields.push('Category');
      if (SubCategory && SubCategory !== SubCategory.toLowerCase()) notLowercasedFields.push('SubCategory');
      if (Brand && Brand !== Brand.toLowerCase()) notLowercasedFields.push('Brand');

      if (notLowercasedFields.length > 0) {
        const notLowercasedStr = notLowercasedFields.join(', ');
        throw new Error(`Row ${index + 1}: Fields must be lowercased (${notLowercasedStr}). Row content: ${JSON.stringify(row)}`);
      }

      const sizeEntry = { size: Size, bust: Bust, waist: Waist, hips: Hips };
      
      Object.keys(sizeEntry).forEach(key => {
        if (sizeEntry[key] === null) {
          delete sizeEntry[key];
        } else if (typeof sizeEntry[key] === 'string' && sizeEntry[key].includes('-')) {
          const [min, max] = sizeEntry[key].split('-').map(Number);
          sizeEntry[key] = Math.max(min, max);
        }
      });

      if (!nestedData[Retailer]) nestedData[Retailer] = {};
      if (!nestedData[Retailer][Type]) nestedData[Retailer][Type] = {};
      if (!nestedData[Retailer][Type][Category]) nestedData[Retailer][Type][Category] = {};
      if (!nestedData[Retailer][Type][Category][SubCategory]) nestedData[Retailer][Type][Category][SubCategory] = {};
      if (!nestedData[Retailer][Type][Category][SubCategory][Brand]) nestedData[Retailer][Type][Category][SubCategory][Brand] = [];

      nestedData[Retailer][Type][Category][SubCategory][Brand].push(sizeEntry);
    });

    const finalData = [];
    for (const [Retailer, types] of Object.entries(nestedData)) {
      for (const [Type, categories] of Object.entries(types)) {
        for (const [Category, subCategories] of Object.entries(categories)) {
          for (const [SubCategory, brands] of Object.entries(subCategories)) {
            for (const [Brand, SizeEntries] of Object.entries(brands)) {
              const filteredSizeEntries = SizeEntries.filter(entry => entry.size !== null);
              finalData.push({
                retailer: Retailer,
                brand: Brand,
                category: Category,
                sub_category: SubCategory,
                type: Type,
                SizeEntries: filteredSizeEntries
              });
            }
          }
        }
      }
    }

    return finalData;
  };

  const uploadToDatabase = async (data) => {
    const brands = [];
    try {
      for (const item of data) {
        const { retailer, brand, category, sub_category, type, SizeEntries } = item;
        
        const sizeChartResult = await API.graphql({
          query: createSizeChart,
          variables: {
            input: { brand, category, sub_category, type, retailer }
          }
        });
        const sizechartID = sizeChartResult.data.createSizeChart.id;
   
  
        for (const entry of SizeEntries) {
    
          const response = await API.graphql({
            query: createSizeEntry,
            variables: {
              input: {
                sizechartID,
                ...entry
              }
            }
          });
       
        }
        brands.push(brand);
      }
      setUploadedBrands(brands);
    } catch (err) {
      console.error("Failed to upload to database:", err);
      if (err.errors && err.errors.length > 0 && err.errors[0].message) {
        setError(`Failed to upload to database: ${err.errors[0].message}`);
      } else {
        setError("Failed to upload to database. Please try again.");
      }
    }
  };
  
  const parseCSV = async (key) => {
    setLoading(true);
    try {
      const result = await Storage.get(key, { download: true, level: 'protected' });
      const text = await result.Body.text();
      const parsed = Papa.parse(text, {
        header: true,
        dynamicTyping: true,
      });
      const jsonData = convertToDesiredJSON(parsed.data);
      if (jsonData) {
        await uploadToDatabase(jsonData);
      } else {
        setError('Unexpected error occurred');
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="p-6">
      {loading ? (
        <p className="text-lg font-semibold">Loading...</p>
      ) : error ? (
        <div >
           <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">An Error Has Occured</h3>
          <div className="mt-2 text-sm text-red-700">
          <p>{error}</p>
          </div>
        </div>
      </div>
    </div>
         
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={resetState}>Try Again</button>
        </div>
      ) : uploadedBrands ? (
        <div className="text-green-500">
          <p className="font-semibold">Your size chart has been successfully uploaded for the following brands: {uploadedBrands.join(', ')}</p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={resetState}>Add More</button>
        </div>
      ) : (
        <StorageManager
          acceptedFileTypes={['.csv']}
          accessLevel="protected"
          maxFileCount={1}
          isResumable
          onUploadSuccess={({ key }) => parseCSV(key)}
        />
      )}
    </div>
  );
};

export default SizeChart;
