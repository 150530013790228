import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listStores } from '../../graphql/queries'; 
import DealCard from './DealCard';

const FeaturedDeals = () => {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const storeData = await API.graphql(graphqlOperation(listStores, {
          filter: {
            featured: {
              eq: true
            }
          }
        }));
        setDeals(storeData.data.listStores.items); 
      } catch (error) {
        console.error('Error fetching featured stores:', error);
      }
    };

    fetchDeals();
  }, []);

  return (
    <div className="py-12 px-12 md:px-24 bg-white">
      <h2 className="text-2xl text-base-100 font-bold mb-4">Featured Stores🔥</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {deals.map((deal) => (
          <DealCard key={deal.id} deal={deal} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedDeals;
