/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "6b038a38cb26419ab478974a1446a13f",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6b038a38cb26419ab478974a1446a13f",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://jm2uk2ecfzaovbdjqczhpq5pju.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-a2hln3ahdfcmdedptunwhpyefi",
    "aws_cloud_logic_custom": [
        {
            "name": "sizeRecommendation",
            "endpoint": "https://p2449vfb07.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "testapi",
            "endpoint": "https://ux5l0lore7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "testexpress",
            "endpoint": "https://5352i8aiyd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:5e83a56f-6bab-4a61-af27-be15d043b5fa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w4Ni8UIuh",
    "aws_user_pools_web_client_id": "6jps57csbdagkeegdmlq4o1jnj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "findmysizecharts92525-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
