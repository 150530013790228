import React, { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Footer from '../components/common/Footer';
import Confetti from 'react-confetti';


const ResultsPage = () => {
  const [storedResults, setStoredResults] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showOtherSizes, setShowOtherSizes] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true)

  useEffect(() => {
    const results = localStorage.getItem('sizeResults');
    const confettiTimer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
    
    if (results) {
      setStoredResults(JSON.parse(results));
    }
    return () => clearTimeout(confettiTimer);
  }, []);

  const storeWebsite = localStorage.getItem('specificStoreWebsite') || 'https://blackfriday.com/';
  if (!storedResults) return <div>Loading...</div>;

  const { overallBestSize, averageFitConfidence, brandResults } = storedResults;

  // Function to render the progress bar for fit confidence
  const renderProgressBar = (confidence) => {
    return (
      <div className="relative pt-1">
       

        <div className="flex mb-2 items-center justify-between">
          <div>
            <span 
              className="inline-block text-xs font-semibold text-gray-500 cursor-pointer"
              onClick={() => setShowInfo(!showInfo)}
            >
              <InformationCircleIcon className="h-4 w-4 inline-block" />
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
              {confidence}%
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div style={{ width: `${confidence}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
        </div>
        {showInfo && (
          <div className="text-xs text-gray-500">
            Fit confidence is based on how well the selected size is expected to fit based on your measurements and preferences.
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-base-100">
       {showConfetti && <Confetti className="z-0" />}
       <div className="flex-1 flex items-center justify-center p-4 z-10">
        <div className="bg-white border-4 border-primary rounded-lg shadow-lg w-full max-w-lg p-4 sm:p-6 md:p-8 text-center">
          <h1 className="text-2xl font-bold mb-4 text-base-100">Your Best Fit</h1>
          
          <div className="flex justify-center items-center text-5xl bg-green-300 rounded-full w-24 h-24 mx-auto text-black font-bold">
    {overallBestSize}
</div>

  
          {renderProgressBar(averageFitConfidence)}
       
          <button 
            onClick={() => setShowOtherSizes(!showOtherSizes)}
            className="text-base-100 cursor-pointer my-4"
          >
            {showOtherSizes ? 'Hide sizes' : 'See other sizes'}
          </button>
  
          {showOtherSizes && (
            <div className="mb-4 overflow-x-auto">
              <h2 className="text-xl font-semibold mb-2">Brand Results</h2>
              <table className="min-w-full border">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Brand</th>
                    <th className="border px-4 py-2">Recommended Size</th>
                    <th className="border px-4 py-2">Fit Confidence</th>
                  </tr>
                </thead>
                <tbody>
                  {brandResults && Object.keys(brandResults).map((brand, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{brand}</td>
                      <td className="border px-4 py-2">{brandResults[brand].overall.recommendedSize}</td>
                      <td className="border px-4 py-2">{brandResults[brand].overall.highestFitConfidence}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
  
  <a href={storeWebsite} className="inline-block w-full bg-secondary text-white py-2 px-4 rounded-lg">
        Start Shopping
      </a>

  
          {showInfo && (
            <div className="text-xs text-gray-500 mt-4">
              Fit confidence is based on how well the selected size is expected to fit based on your measurements and preferences.
            </div>
          )}
        </div>
      </div>
  
      <Footer />
    </div>
  );
  
          }
export default ResultsPage;
